@use '../variables';

img.integral{
    //height: 17px;
    grid-row-start: 2;
    grid-column-start: 1;
}

/* change of properties => span added to img */
.simple-box.integral > span {
    height: auto;
    grid-row-start: 2;
    grid-column-start: 1;
}

.simple-box.integral > strong {
    height: auto;
    grid-row-start: 2;
    grid-column-start: 1;
}

.simple-box.integral > i {
    height: auto;
    grid-row-start: 2;
    grid-column-start: 1;
}

.small-box.upper-box.integral {
    margin-left: 0.1em;
}

.small-box.lower-box.integral {
    margin-right: 0.38em;
}