@use '../variables';

img.sum{
    //height: 17px;
    grid-row-start: 2;
    grid-column-start: 1;
}

/* change of properties => span added to img */
.simple-box.sum > span {
    height: auto;
    grid-row-start: 2;
    grid-column-start: 1;
}

.simple-box.sum > strong {
    height: auto;
    grid-row-start: 2;
    grid-column-start: 1;
}

.simple-box.sum > i {
    height: auto;
    grid-row-start: 2;
    grid-column-start: 1;
}

.small-box.sum:not(.text-box){
    margin-right: 0.2em;
}