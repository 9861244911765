@use '../variables';

span.ck-widget.command:hover {
    //outline-color: black;
    outline: transparent;
    cursor: move;
}

.command {
    display: inline-block;
    vertical-align: middle;
    align-content: center;
    height: auto;
    font-family: 'Yantramanav', sans-serif, 'LMRoman10-Regular';
}

span.ck-widget.command {
    background-color: #ffe482 !important;
    border-radius: 1em;
}

.command .small-box:hover {
    cursor: auto;
}

p > .command {
    // adjust baseline
    margin-bottom: 0.2em;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
    padding-top: 0;
    padding: 0.05em 0;
}

.command > span {
    place-self: center;
}

.command > strong {
    place-self: center;
}

.command > i {
    place-self: center;
}

.command span.small-box {
    min-width: 6px;
    height: 100%;
    border: none !important;
    vertical-align: top;
    text-align: left;
    line-height: normal;
    margin-left: 0.6em;
    margin-right: 0.6em;
    margin-top: 0.15em;
    background: transparent !important;
}

.command span.small-box.text-box > br {
    content: "";
    display: inline-block;
    width: 0.25em;
    height: 1em;
}

.command span.small-box:focus > br {
    content: none; //remove "content" when cursor is in box
}

.command span.text-box {
    font-size: max( var(--tiny), 0.8em );
    min-width: 0.25em;
    height: auto;
    border: none !important;
    color: black !important;
}

.command span.text-box span {
    color: black !important;
}

.command span.text-box strong {
    font-weight: normal !important;
}

.command span.text-box i {
    font-style: normal !important;
}

.command span {
    // elements inside a command can't have their own bg-color
    background-color: inherit !important;
}