@use '../variables';

.simple-box.mathBox {
    display: inline-block;
}

.ck-widget.simple-box.mathBox:hover {
    outline-color: green;
    //cursor: url('grab.png') 16 16, auto;	
}

.simple-box .small-box.text-box.mathBox {
    display: inline-block;
    font-size: 1em !important;
}

.simple-box .small-box.text-box.mathBox > span {
    font-size: 1em !important;
}