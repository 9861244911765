@use 'variables';
@import './symbols/simpleIntegral.scss';
@import './symbols/simpleSum.scss';
@import './symbols/mathBox.scss';
@import './symbols/command.scss';

.simple-box {
    display: inline-grid;
    vertical-align: middle;
    align-content: center;
    height: auto;
    grid-template-columns: auto 0 auto;
    /*make top and bottom box equal height, and at least 20 % of the container height, to make 
    sure the boxes are always clickable*/
    grid-auto-rows: minmax(20%, 1fr) minmax(auto, 1em) minmax(20%, 1fr);
    font-family: 'computer_modernmedium', 'LMRoman10-Regular';
}

.simple-box:hover {
    cursor: move;
}

.simple-box .small-box:hover {
    cursor: auto;
}

p > .simple-box {
    // adjust baseline
    margin-bottom: 0.1em;
    padding-left: 0.2em;
    padding-right: 0.2em;
    padding-bottom: 0.2em;
    padding-top: 0.2em;
}


.simple-box > img {
    height: 1rem; //default
    place-self: center;
}

.simple-box > span {
    place-self: center;
}

.simple-box > strong {
    place-self: center;
}

.simple-box > i {
    place-self: center;
}


.simple-box > span img {
    height: 1rem; //default
    vertical-align: bottom; //don't ask me why
} 

.simple-box > strong img {
    height: 1rem; //default
    vertical-align: bottom; //don't ask me why
}

.simple-box > i img {
    height: 1rem; //default
    vertical-align: bottom; //don't ask me why
}


.simple-box span.small-box {
    font-size: max( var(--tiny), 0.7em );
    min-width: 6px;
    height: 100%;
    border: none !important;
    vertical-align: middle;
    text-align: center;
    line-height: normal;
}

.simple-box span.small-box > br {
    content: "";
    display: block;
    width: 0.7em;
    height: 100%;
}

.simple-box span.small-box.text-box > br {
    content: "";
    display: block;
    width: 0.25em;
    height: 1em;
}

.simple-box span.small-box:focus > br {
    content: none; //remove "content" when cursor is in box
}

.simple-box span.text-box {
    font-size: 1em;
    min-width: 0.25em;
    height: auto;
    grid-column-start: 3;
    grid-row-start: 2;
    border: none !important;
}

.simple-box span.small-box.upper-box {
    grid-row-start: 1;
    grid-column-start: 1;
    align-self: end;
}

.simple-box span.small-box.lower-box {
    grid-column-start: 1;
    grid-row-start: 3;
    align-self: start;
}

.simple-box:not(.mathBox) span.small-box > span {
    //don't change font size when color is changed
    font-size: 1em !important;
}

.simple-box:not(.mathBox) span.text-box > span {
    //don't change font size when color is changed
    font-size: 1em !important;
}

.simple-box:not(.mathBox) > .small-box > .simple-box > span {
    //box in small box
    font-size: 1em !important;
}

.simple-box > .small-box > .simple-box img {
    //img in small box
    height: max( var(--tiny), 1em ) !important;
}

.simple-box > .small-box > .simple-box > .small-box:not(.text-box) {
    //small box in small box
    font-size: max(var(--tiny), 0.7em) !important;
}

.simple-box span {
    // elements inside a simple-box can't have their own bg-color
    background-color: inherit !important;
}