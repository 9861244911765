/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on July 4, 2021 */



@font-face {
    font-family: 'computer_modernmedium';
    src: url('cmmi10-webfont.woff2') format('woff2'),
         url('cmmi10-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}