@use 'variables';

html {
    font-size: clamp(15px, 1.38vw, 18px);
}

body{
    background-color: var(--bg);
    width: 100%;
    margin: 0;

    //maintenance mode
    //overflow: hidden;
}

#maintenance-overlay {
    background-color: #004a01;
    color: #fcffcf;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    position: absolute;
    z-index: 10000000;
    font-family: 'Gotu', 'Courier New', monospace;
}

#maintenance-message {
    position: absolute;
    top: 50%;
    left: 50%;
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    text-align: center;
}

#maintenance-message h1 {
    font-size: 4.2rem;
    line-height: 4.6rem;
    margin: 0 0 1rem 0;
}

#maintenance-message span {
    font-size: 1.25rem;
}

.testbuttons {
    margin-bottom: 5px;
    display: inline-block;
}

#CKtoLATEX {
    margin-bottom: 5px;
}

.temporary-textfield {
    width: 100%;
    height: 20vh;
    font-size: 0.6rem;
    padding: 0;
    box-sizing: border-box;
}

#latex-head {
    height: 8vh;
}

#output {
    width: 100%;
    height: 20vh;
    font-size: 0.6rem;
    padding: 0;
    box-sizing: border-box;
}

#editing-container {
    height: var(--editorHeight);
    width: 100%;
    display: flex;
    align-items: stretch;
}


#pdf-container {
    width: 100%;
    height: 100%;
    //display: inline-block;
    //flex-grow: 1;
}

#pdf-js-viewer {
    width: 100%;
    height: 100%;
}



div.ck.ck-editor {
    width: 100%;
    height: 100%;
    /*transform: scale( var(--scaling) );
    transform-origin: top left;*/
    //display: inline-block;
    //flex-grow: 1;
}


.ck.ck-editor__main {
    height: 100%;
}

div.ck.ck-editor > .ck.ck-editor__top.ck-reset_all {
    float: left;
}

div.ck.ck-editor > .ck.ck-editor__top {
    height: 100%;
    background-color: #004410;
}

div.ck > div.ck.ck-toolbar.ck-toolbar_grouping {
    width: clamp(60px, 5.5vw, 70px);
    height: var(--editorHeight);
    //align-items: start;
    display: flex;
    flex-direction: column;
    background: transparent;
}

.ck.ck-toolbar > div.ck-toolbar__items {
    flex-flow: column wrap;
    height: fit-content;
    width: 100%;
}

.ck.ck-toolbar > div.ck-toolbar__items > div {
    width: 100%;
    margin-right: 0 !important;
}

.ck.ck-toolbar > div.ck-toolbar__items > button {
    width: 100%;
    margin-right: 0 !important;
}

.ck.ck-toolbar > div.ck-toolbar__items .ck.ck-button.ck.ck-button  {
    justify-content: center;
}

.ck.ck-toolbar svg {
    color: white;
}

.ck.ck-toolbar > div.ck-toolbar__items .ck-button__label {
    color: white;
}

.ck.ck-toolbar > div.ck-toolbar__items .ck-dropdown__panel .ck-button__label {
    color: black;
}

.ck.ck-toolbar .ck-dropdown__panel svg {
    color: black;
}

.ck.ck-toolbar.ck.ck-toolbar__separator {
    display: none;
}

.ck.ck-dropdown.ck-toolbar__grouped-dropdown.ck-toolbar-dropdown {
    height: 100%;
}

.ck.ck-toolbar .ck.ck-dropdown__panel.ck-dropdown__panel_sw.ck-dropdown__panel-visible {
    bottom: 0;
    top: auto;
    right: auto;
    left: 0;
}

.ck.ck-toolbar .ck.ck-dropdown__panel.ck-dropdown__panel_sw.ck-dropdown__panel-visible svg {
    color: black;
}

.ck.ck-toolbar .ck.ck-dropdown__panel.ck-dropdown__panel_sw.ck-dropdown__panel-visible .ck-button__label{
    color: black;
}

.ck.ck-balloon-rotator svg {
    color: black;
}

div.ck.ck-editor > .ck.ck-editor__main {
    background-color: var(--lightGrey);
    display: block;
    box-sizing: border-box;
    padding: 0;
    overflow: auto;
}

.ck.ck-editor__main > .ck-editor__editable {
    padding: 0.35rem 0.4rem;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    overflow: auto;
}

.ck.ck-editor__editable_inline>:first-child {
    margin-top: 0 !important;
}

.ck.ck-editor__editable_inline>:last-child {
    margin-bottom: 0 !important;
}

.ck-content {
    /* default values */
    /*font-family: Ubuntu, Arial, sans-serif;*/
    font-family: "LMRoman10-Regular";
    /*font-family: "computer_modernmedium";*/
}

.ck-content p {
    display: block;
    margin-block-start: 0;
    margin-block-end: 0;
    font-size: var(--normalsize);
    width: 100%;
    //text-indent: var(--indent);
    line-height: normal;
    hyphens: auto;
    //word-spacing: -0.45px;
}

.ck-content p > span {
    line-height: normal;
}

.ck-content span {
    text-indent: 0;
}

.ck-content div {
    text-indent: 0;
}

.ck-editor .ck-content .text-tiny {
    font-size: 0.6em;
}

.ck-editor .ck-content .text-scriptsize {
    font-size: 0.75em;
}

.ck-editor .ck-content .text-small {
    font-size: 0.85em;
}

.ck-editor .ck-content .text-big {
    font-size: 1.4em;
}

.ck-editor .ck-content .text-huge {
    font-size: 1.8em;
}