:root {
    --bg: rgb(126, 240, 255);
    --lightGrey: #dadada;


    --containerRealWidth: 97; //vw

    //--containerWidth: 97vw;
    //--containerRealWidth: 97; //%
    --editorZoom: 0.9;

    //--editorWidth: 0.8;
    //--editorMargin: calc( (1 - var(--editorWidth))/2 );

    //--pt: 1.5; /*1pt=1.5px*/

    --paperMargin: 1.875; // inches, A4
    //--paperWidth: 8.27; // inches, A4
    --paperWidth: 8.5; // inches, US letter
    --paperHeight: 11; // inches, US letter

    //--editorWidth: calc( var(--editorZoom) * var(--containerWidth) ); //vw

    //--editorMargin: calc( (var(--containerWidth) - var(--editorWidth))/2 ); //vw

    // how many REM is one inch in the editor?
    // 100 REM = 1 editorWidth
    //--editorOneInchWidth: calc( 100rem/var(--paperWidth) ); // rem

    //--editorHeight: calc( var(--editorOneInchWidth) * var(--paperHeight) ); //rem

    --normal: 10; // normalsize in pt
    --normalRef: 17.15px; // best resolution for normalsize font (px)
    --pt: calc( var(--normalRef) / var(--normal) ); // 1 pt in editor expressed in px

    //--pt: calc(var(--editorOneInchWidth)/72.27); // 1 pt in editor expressed in rem

    --normalfactor: 10;
    
    --tiny: calc(5 * var(--pt));
    --scriptsize: calc(7 * var(--pt));
    --footnotesize: calc(8 * var(--pt));
    --small: calc(9 * var(--pt));
    --normalsize: calc(var(--normalfactor) * var(--pt));
    --large: calc(12 * var(--pt));
    --Large: calc(14 * var(--pt));
    --huge: calc(20 * var(--pt));
    --Huge: calc(25 * var(--pt));
    
    --indent: calc(1.5 * var(--normalsize));

    --editorOneInchWidth: calc( var(--pt)*72.27 ); //(px)

    --editorHeight: calc( var(--editorOneInchWidth) * var(--paperHeight)); //px

    --ev: calc( var(--editorOneInchWidth) * var(--paperWidth) ); //(px)

    --editorWidth: calc(var(--ev)); //px

    --cw: calc( var(--ev) / var(--editorZoom) ); //(px)

    --containerWidth: calc(var(--cw)); //px

    --editorMargin: calc( (var(--containerWidth) - var(--editorWidth))/2 ); //px

    //--containerWidthDiff: calc( var(--containerWidth) - var(--containerRealWidth) ); //px ?

    //--scaleFactor: calc( var(--containerRealWidth) / var(--cw) );

    //$scaleFactor: $containerRealWidth / var(--cw);

    //$transform: 1 - $scaleFactor;


    //--transform: calc( 1 - var(--scaleFactor));

    --scaling: 1;


    // NEW VARIABLES:

    --editorHeight: 80vh;
    --editorWidth: 50vw;
    --ptToRem: 0.1rem;

    --tiny: calc(5 * var(--ptToRem));
    --scriptsize: calc(7 * var(--ptToRem));
    --footnotesize: calc(8 * var(--ptToRem));
    --small: calc(9 * var(--ptToRem));
    --normalsize: calc(var(--normalfactor) * var(--ptToRem));
    --large: calc(12 * var(--ptToRem));
    --Large: calc(14 * var(--ptToRem));
    --LARGE: calc(17 * var(--ptToRem));
    --huge: calc(20 * var(--ptToRem));
    --Huge: calc(25 * var(--ptToRem));


}


//$scaleFactor: $containerRealWidth / var(--cw);

//$transform: 0.7;

/* exact normal: 17.15px */


/*$containerRealWidth: 97; //*1 vw pixels

$editorZoom: 0.9;

$paperMargin: 1.875; // inches, A4

$paperWidth: 8.5; // inches, US letter
$paperHeight: 11; // inches, US letter

$normal: 10; // normalsize in pt
$normalRef: 17.15; // best resolution for normalsize font (px)
$pt: $normalRef / $normal; // 1 pt in editor expressed in (px)

$indent: 20 * $pt * 1px;

$editorOneInchWidth: $pt*72.27; //(px)

$editorHeight: $editorOneInchWidth*$paperHeight* 1px; //px

$ev: $editorOneInchWidth*$paperWidth; //(px)

$editorWidth: $ev*1px; //px

$cw: $ev / $editorZoom; //(px)

$containerWidth: $cw*1px; //px

$editorMargin: $containerWidth - $editorWidth/2; //px

$scaleFactor: $containerRealWidth / $cw;

$transform: 1 - $scaleFactor;*/

//TODO: Line-height, width